import './App.scss';
import Equipment from './pages/Equipment/Equipment';
import Excavation from './pages/Excavation/Excavation';
import HomePage from './pages/HomePage/HomePage';
import HotTubs from './pages/HotTubs/HotTubs';
import OverviewPage from './pages/OverviewPage/OverviewPage';
import QualityBuildPage from './pages/QualityBuildPage/QualityBuildPage';
import SafetyCover from './pages/SafetyCover/SafetyCover';
import CanadianQuality from './pages/CanadianQuality/CanadianQuality';

function App() {
  return (
    <div className="App">
      <HomePage />
      <CanadianQuality />
      <QualityBuildPage />
      <Equipment />
      <Excavation />
      <SafetyCover />
      <HotTubs />
    </div>
  );
}

export default App;
