import React from 'react';
import './HomePage.scss';

const HomePage = () => {
    return (
        <div className='homepage'>
            <section className="homepage__header">
                <h1 className="homepage__title">Jameson Pool Landscape</h1>
                <h3 className="homepage__year">2025</h3>
            </section>

            <section className="homepage__image">
                
            </section>

            <section className="homepage__quote">
                <h4 className="homepage__attribute">Quality</h4>
                <h4 className="homepage__attribute">Efficiency</h4>
                <h4 className="homepage__attribute">Innovation</h4>
            </section>
        </div>
    );
};

export default HomePage;