import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faTools, faCubes } from '@fortawesome/free-solid-svg-icons';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons'; // Correct import
import './CanadianQuality.scss';

const CanadianQuality = () => {
    return (
        <div className="canadian-quality">
            <h2 className="canadian-quality__title">
                Proudly <span className="canadian-quality__title--highlight">
                <FontAwesomeIcon icon={faCanadianMapleLeaf} className="canadian-quality__leaf" />  Canadian <FontAwesomeIcon icon={faCanadianMapleLeaf} className="canadian-quality__leaf" />
                </span> Materials
            </h2>

            <div className="canadian-quality__grid">
                <div className="canadian-quality__tile">
                    <FontAwesomeIcon icon={faIndustry} className="canadian-quality__icon" />
                    <h4>Steel Pool Kits</h4>
                    <p>Manufactured in <strong>Mississauga</strong>, our steel pool kits provide unmatched strength and precision engineering.</p>
                </div>

                <div className="canadian-quality__tile">
                    <FontAwesomeIcon icon={faTools} className="canadian-quality__icon" />
                    <h4>Gunite & Concrete</h4>
                    <p>Sourced from <strong>Oakville</strong>, our high-quality gunite and concrete ensure long-lasting structural integrity.</p>
                </div>

                <div className="canadian-quality__tile">
                    <FontAwesomeIcon icon={faCubes} className="canadian-quality__icon" />
                    <h4>Liners & Patio Stones</h4>
                    <p>Most of our <strong>vinyl liners and patio stones</strong> are Canadian-made, providing durability and timeless beauty.</p>
                </div>
            </div>
        </div>
    );
};

export default CanadianQuality;
